<template>
    <div class="page">
        <div class="school">班级：六1班</div>
        <div class="student">
            <div class="student_name">姓名:&nbsp;&nbsp;刘诗诗</div>
            <div class="goods-list">
                <div class="goods-item">
                    <div class="name">长郡雨外洪塘学校小学部新生班服（二件)男女同款</div>
                    <div class="num">数量:<span>&nbsp;&nbsp;1</span></div>
                </div>
                <div class="goods-item">
                    <div class="name">长郡雨外洪塘学校小学部冬裤</div>
                    <div class="num">数量:<span>&nbsp;&nbsp;2</span></div>
                </div>
    
            </div>
        </div>
    
        <div class="student">
            <div class="student_name">姓名:&nbsp;&nbsp;刘亦菲</div>
            <div class="goods-list">
                <div class="goods-item">
                    <div class="name">长郡雨外洪塘学校小学部冬裤</div>
                    <div class="num">数量:<span>&nbsp;&nbsp;2</span></div>
                </div>
        
            </div>
        </div>
        
        
    </div>
</template>

<script>

export default {
    name: "detail",
    data() {
        return {
        
        }
    },
    
    methods: {
    
    },
    mounted() {
    
    }
}
</script>

<style scoped lang="less">
.page { background-color: #f4f4f4; height: 100vh; overflow-y: auto}
.school {
    padding: 0 29px;
    height: 38px;
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #212121;
    line-height: 38px;
    background-color: #fff;
    position: relative;
    z-index: 2;
}
.student {
    background-color: #fff;
    margin-top: 10px;
    .student_name {
        padding: 0 29px;
        height: 38px;
        font-size: 12px;
        font-weight: 500;
        color: #212121;
        line-height: 38px;
        background-color: #fff;
        position: relative;
        z-index: 2;
        border-bottom: 1px solid #EEEDEC;
    }
    .goods-list {
        padding: 0 28px;
       
        .goods-item {
            padding: 10px 0;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            border-bottom: 1px solid #EEEDEC;
            &:last-child {
                border-bottom: none;
            }
            .name {
                font-size: 12px;
                color: #212121;
                line-height: 20px;
                width: 220px;
                word-break: normal;
            }
            
            .num {
                font-size: 10px;
                color: #999999;
                line-height: 20px;
                span {color: #f00;}
            }
        }
    }
}

</style>
